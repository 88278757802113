import React, { createContext, useState, useContext, useEffect } from 'react';
import { login as apiLogin, signUp as apiSignUp } from '../api/auth';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // TODO: 토큰 유효성 검사 로직 추가
      setUser({ token });
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await apiLogin(email, password);
      const { token } = response;
      localStorage.setItem('token', token);
      setUser({ token });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const signUp = async (email, password) => {
    try {
      const response = await apiSignUp(email, password);
      const { token } = response;
      localStorage.setItem('token', token);
      setUser({ token });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'/api/auth/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        console.log('서버 로그아웃 성공');
      } else {
        console.log(`서버 로그아웃 실패: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('로그아웃 중 서버 통신 오류:', error);
    } finally {
      // 서버 응답과 관계없이 항상 로컬에서 로그아웃 처리
      localStorage.removeItem('token');
      setUser(null);
      return true; // 항상 로그아웃 성공으로 처리
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, signUp }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);