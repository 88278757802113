import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/Layout';
import Login from './components/Login';
import GlobalStyle from './styles/GlobalStyle';

function MainApp() {
  const { user } = useAuth();

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route 
            path="/login" 
            element={user ? <Navigate to="/" replace /> : <Login />} 
          />
          <Route 
            path="/" 
            element={
              user ? (
                <Layout>
                  <h1>Welcome to ORDARS, {user.username}!</h1>
                  {/* 여기에 메인 컨텐츠를 추가하세요 */}
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  );
}

export default App;