import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import TopBar from './TopBar';
import Menu from './Menu';
import Sidebar from './Sidebar';
import Bottom from './Bottom';
import Popup from './Popup';
import Layer from './Layer';
import useHistory from '../hooks/useHistory';
import { useAuth } from '../contexts/AuthContext';

const theme = {
  primary: '#3498db',
  secondary: '#2ecc71',
  background: '#f4f6f8',
  text: '#2c3e50',
  border: '#e0e0e0',
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.text};
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${props => props.theme.background};
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
  transition: all 0.3s ease;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    &.align_right {
      justify-content: flex-end;
    }
  }  
`;

const Main = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media screen and (max-width: 768px) {
    margin: 10px;
    padding: 15px;
  }  
`;

const Button = styled.button`
  cursor: pointer;
  padding: 10px 15px;
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
  transition: all 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  user-select:none;

  &:hover {
    background-color: ${props => props.theme.secondary};
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

function Layout({ children }) {  
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isBottomOpen, setIsBottomOpen] = useState(false);
    const [popup, setPopup] = useState(null);
    const [wrapLayers, setWrapLayers] = useState([]);
    const [mainLayers, setMainLayers] = useState([]);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { pushHistory, popHistory, removeLastHistory } = useHistory();

    const handleLogout = async () => {
      const success = await logout();
      if (success) {
        alert('로그아웃되었습니다.');
        navigate('/');
      } else {
        alert('로그아웃 중 오류가 발생했습니다.');
      }
    };
  
    const toggleMenu = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
        removeLastHistory();
      } else {
        setIsMenuOpen(true);
        pushHistory(() => setIsMenuOpen(false));
      }
    };
  
    const toggleBottom = () => setIsBottomOpen(!isBottomOpen);
  
    const openPopup = (message, title) => {
      setPopup({ message, title });
      pushHistory(() => setPopup(null));
    };
  
    const closePopup = () => {
      setPopup(null);
      removeLastHistory();
    };
  
    const wrapLayerOpen = () => {
        if (wrapLayers.length < 10) {
          setWrapLayers(prev => [...prev, { type: 'wrap', id: Date.now() }]);
          pushHistory(() => setWrapLayers(prev => prev.slice(0, -1)));
        }
      };
    
      const mainLayerOpen = () => {
        if (mainLayers.length < 10) {
          setMainLayers(prev => [...prev, { type: 'main', id: Date.now() }]);
          pushHistory(() => setMainLayers(prev => prev.slice(0, -1)));
        }
      };
    
      const closeWrapLayer = (id) => {
        setWrapLayers(prev => prev.filter(layer => layer.id !== id));
        removeLastHistory();
      };
      
      const closeMainLayer = (id) => {
        setMainLayers(prev => prev.filter(layer => layer.id !== id));
        removeLastHistory();
      };
    
      useEffect(() => {
        const handlePopState = () => {
          const lastAction = popHistory();
          if (lastAction) lastAction();
        };
    
        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
      }, [popHistory]);
    
      return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Wrapper>
                <TopBar onMenuToggle={toggleMenu} onLogout={handleLogout} />
                <Center>
                    <Menu isOpen={isMenuOpen} />
                    <Main>
                        {children}
                        <Button onClick={toggleBottom}>하단</Button>
                        <Button onClick={() => openPopup("테스트 팝업", "알림")}>팝업 열기</Button>
                        <Button onClick={wrapLayerOpen}>wrapLayer열기</Button>
                        <Button onClick={mainLayerOpen}>mainLayer열기</Button>
                        {mainLayers.map((layer) => (
                            <Layer 
                                key={layer.id} 
                                type={layer.type} 
                                onClose={() => closeMainLayer(layer.id)} 
                                isMainLayer 
                                onOpenNewLayer={mainLayerOpen}
                            />
                        ))}
                    </Main>
                    <Sidebar />
                </Center>
                <Bottom isOpen={isBottomOpen} />
                {popup && <Popup {...popup} onClose={closePopup} />}
                {wrapLayers.map((layer) => (
                    <Layer 
                        key={layer.id} 
                        type={layer.type} 
                        onClose={() => closeWrapLayer(layer.id)} 
                        onOpenNewLayer={wrapLayerOpen}
                    />
                ))}
            </Wrapper>
        </ThemeProvider>
    );
}

export default Layout;