import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaBars, FaTimes, FaExpandAlt, FaCompressAlt, FaUser, FaClipboard, FaCog } from 'react-icons/fa';


const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const LayerWrapper = styled.div`
  position: ${props => props.isMainLayer ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: ${props => props.isMainLayer ? 'flex-start' : 'center'};
  z-index: 10;
  animation: ${fadeIn} 0.1s ease-out;

  &.white {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const LayerContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease;
  animation: ${slideIn} 0.1s ease-out;
  height: ${props => props.isMainLayer || props.type === 'wrap' ? 'calc(100% - 40px)' : 'auto'};
  min-height: 300px;
  width: ${props => props.isExpanded ? 'calc(100% - 40px)' : '80%'};
  max-width: ${props => props.isExpanded ? 'none' : '1200px'};

  ${props => props.isMainLayer || props.type === 'wrap' ? css`
    margin: 20px;
  ` : css`
    max-height: calc(100% - 40px);
  `}

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px) !important;
    height: calc(100% - 40px) !important;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const LayerTop = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: ${props => props.type === 'wrap' ? '#2c3e50' : '#2c3e50'};
  color: #fff;
  user-select:none;

  &.hidden {
    display: none;
  }
`;

const LayerTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-weight: 600;
  font-size: 18px;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const LayerCenter = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow: hidden;
`;

const LayerCenterLeft = styled.div`
  width: 220px;
  background-color: ${props => props.type === 'wrap' ? '#34495e' : '#34495e'};
  color: #fff;
  overflow-y: auto;
  transition: all 0.1s ease;
  user-select:none;

  &.hidden {
    margin-left: -220px;
  }
`;

const LayerCenterMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LayerCenterMainTable = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
`;

const LayerBottom = styled.div`
  padding: 15px 20px;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
`;

const NewLayerButton = styled.button`
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease;
  
  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const LayerCenterMainMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  user-select:none;
`;

const SelectedMenuLabel = styled.div`
  font-weight: 800;
  margin-right: 15px;
  font-size: 14px;  
  color:#fe5500;

  &:hover{
    cursor:pointer;
  }
`;

const SubMenuItem = styled.div`
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.1s ease;
  font-weight: ${props => props.active ? '800' : '400'};
  font-size: ${props => props.active ? '14px' : '12px'};
  color: #495057;

  &:hover {
    font-weight:800;
    font-size:14px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.1s;
  background-color: ${props => props.active ? '#2c3e50' : 'transparent'};

  &:hover {
    background-color: #2c3e50;
  }
`;

function Layer({ 
  type,                    // 레이어의 타입을 지정 (예: 'wrap', 'modal' 등)
  onClose,                 // 레이어를 닫을 때 호출되는 함수
  isMainLayer = false,     // 메인 레이어인지 여부 (true일 경우 위치 및 스타일이 다르게 적용됨)
  onOpenNewLayer,          // 새 레이어를 열 때 호출되는 함수
  showCloseButton = true,  // 닫기 버튼 표시 여부
  showMenuButton = true,   // 왼쪽 메뉴 토글 버튼 표시 여부
  showBottomContent = true,// 하단 컨텐츠 영역 표시 여부
  showMainMenu = true,     // 메인 메뉴(서브 메뉴) 표시 여부
  showResizeButton = true, // 레이어 크기 조절 버튼 표시 여부
  initialExpanded = false, // 초기 레이어 확장 상태 (true일 경우 최대화된 상태로 시작)
}) {
    const [layerOptions, setLayerOptions] = useState({
      showCloseButton,
      showMenuButton,
      showBottomContent,
      showMainMenu,
      showResizeButton,
      initialExpanded
    });

    const [leftMenuHidden, setLeftMenuHidden] = useState(false);
    const [isExpanded, setIsExpanded] = useState(initialExpanded);

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const menus = [
      { id: 'profile', name: '프로필', icon: FaUser, subMenus: ['개인정보', '보안설정', '테스트'] },
      { id: 'orders', name: '주문관리', icon: FaClipboard, subMenus: ['주문목록', '배송현황'] },
      { id: 'settings', name: '설정', icon: FaCog, subMenus: ['일반설정', '알림설정'] },
    ];

    useEffect(() => {
      if (layerOptions.showMenuButton && menus.length > 0) {
        setActiveMenu(menus[0].id);
        if (layerOptions.showMainMenu && menus[0].subMenus.length > 0) {
          setActiveSubMenu(menus[0].subMenus[0]);
        }
      } else if (layerOptions.showMainMenu && menus.length > 0 && menus[0].subMenus.length > 0) {
        setActiveSubMenu(menus[0].subMenus[0]);
      }
    }, []);
  
    const handleMenuClick = (menuId) => {
      setActiveMenu(menuId);
      const selectedMenu = menus.find(menu => menu.id === menuId);
      if (layerOptions.showMainMenu && selectedMenu.subMenus.length > 0) {
        setActiveSubMenu(selectedMenu.subMenus[0]);
      } else {
        setActiveSubMenu(null);
      }
    };

    const handleSubMenuClick = (subMenu) => {
      setActiveSubMenu(subMenu);
    };

    const renderContent = () => {
      if (!activeSubMenu && !activeMenu) return <p>기본 콘텐츠입니다.</p>;

      if (activeSubMenu) {
        switch (activeSubMenu) {
        case '개인정보':
          return <p>여기에 사용자의 개인정보 양식이 표시됩니다.</p>;
        case '보안설정':
          return <p>비밀번호 변경 및 2단계 인증 설정 옵션이 여기에 표시됩니다.</p>;
        case '주문목록':
          return <p>최근 주문 내역 및 상세 정보가 여기에 나열됩니다.</p>;
        case '배송현황':
          return <p>현재 진행 중인 배송의 실시간 추적 정보가 여기에 표시됩니다.</p>;
        case '일반설정':
          return <p>언어, 시간대 등의 일반적인 설정 옵션이 여기에 표시됩니다.</p>;
        case '알림설정':
          return <p>이메일, 푸시 알림 등의 알림 설정 옵션이 여기에 표시됩니다.</p>;
        default:
          return <p>선택된 메뉴: {activeSubMenu}</p>;
        }
      } else if (activeMenu) {
          return <p>선택된 메뉴: {menus.find(menu => menu.id === activeMenu).name}</p>;
      }
    };
  
    useEffect(() => {
      // API 호출 로직 (이전과 동일)
    }, []);
  
    const toggleLeftMenu = () => {
      setLeftMenuHidden(!leftMenuHidden);
    };

    const handleClose = (e) => {
      console.log('Close button clicked');
      e.stopPropagation();
      if (onClose) {
        console.log('Calling onClose function');
        onClose();
      } else {
        console.log('onClose function is not defined');
      }
    };
  
    const handleWrapperDoubleClick = (e) => {
      if (e.target === e.currentTarget && layerOptions.showCloseButton) {
        handleClose(e);
      }
    };

    const toggleLayerSize = () => {
      setIsExpanded(!isExpanded);
    };
  
    const getSelectedMenuName = () => {
      const selectedMenu = menus.find(menu => menu.id === activeMenu);
      return selectedMenu ? selectedMenu.name : '';
    };

    useEffect(() => {
      if (menus.length > 0) {
        if (layerOptions.showMenuButton || layerOptions.showMainMenu) {
          setActiveMenu(menus[0].id);
        }
        if (layerOptions.showMainMenu && menus[0].subMenus.length > 0) {
          setActiveSubMenu(menus[0].subMenus[0]);
        }
      }
    }, []);

    return (
      <LayerWrapper 
        className={layerOptions.layerWrap_white_background} 
        isMainLayer={isMainLayer}
        onDoubleClick={handleWrapperDoubleClick}
      >
        <LayerContent 
          width={layerOptions.layerWidth} 
          className={layerOptions.layerWidth} 
          isMainLayer={isMainLayer}
          type={type}
          isExpanded={isExpanded}
        >
          <LayerTop className={layerOptions.top} type={type}>
            <LayerTitle>
              {layerOptions.showMenuButton && (
                <MenuButton onClick={toggleLeftMenu}>
                  <FaBars />
                </MenuButton>
              )}
              타이틀
            </LayerTitle>
            {layerOptions.showResizeButton && (
              <IconButton onClick={toggleLayerSize}>
                {isExpanded ? <FaCompressAlt /> : <FaExpandAlt />}
              </IconButton>
            )}
            {layerOptions.showCloseButton && (
              <IconButton onClick={handleClose}><FaTimes /></IconButton>
            )}
          </LayerTop>
        <LayerCenter>
          {layerOptions.showMenuButton && (
            <LayerCenterLeft className={leftMenuHidden ? "hidden" : ""} type={type}>
              {menus.map((menu) => (
                <MenuItem 
                  key={menu.id} 
                  onClick={() => handleMenuClick(menu.id)}
                  active={activeMenu === menu.id}
                >
                  <menu.icon style={{ marginRight: '10px' }} />
                  {menu.name}
                </MenuItem>
              ))}
            </LayerCenterLeft>
          )}
          <LayerCenterMain>
          {layerOptions.showMainMenu && (
            <LayerCenterMainMenu>
              {activeMenu && (
                <SelectedMenuLabel onClick={toggleLeftMenu}>{getSelectedMenuName()}</SelectedMenuLabel>
              )}
              {activeMenu && menus.find(menu => menu.id === activeMenu)?.subMenus.map((subMenu) => (
                <SubMenuItem 
                  key={subMenu} 
                  onClick={() => handleSubMenuClick(subMenu)}
                  active={activeSubMenu === subMenu}
                >
                  {subMenu}
                </SubMenuItem>
              ))}
            </LayerCenterMainMenu>
          )}
          <LayerCenterMainTable>
            {renderContent()}
            <NewLayerButton onClick={onOpenNewLayer}>새 레이어 열기</NewLayerButton>
          </LayerCenterMainTable>
          </LayerCenterMain>
        </LayerCenter>
          {layerOptions.showBottomContent && (
            <LayerBottom>
              Bottom Content
            </LayerBottom>
          )}
        </LayerContent>
      </LayerWrapper>
    );
  }
  
  export default Layer;