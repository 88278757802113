import { useState } from 'react';

const useHistory = () => {
  const [history, setHistory] = useState([]);

  const pushHistory = (action) => {
    setHistory(prev => [...prev, action]);
    window.history.pushState(null, '', window.location.pathname);
  };

  const popHistory = () => {
    if (history.length > 0) {
      const newHistory = [...history];
      const lastAction = newHistory.pop();
      setHistory(newHistory);
      return lastAction;
    }
    return null;
  };

  const removeLastHistory = () => {
    if (history.length > 0) {
      setHistory(prev => prev.slice(0, -1));
      window.history.back();
    }
  };

  return { pushHistory, popHistory, removeLastHistory };
};

export default useHistory;