import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SignUp from './SignUp'; 
import { login } from '../api/auth';
import logoImage from '../images/logo.png';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
`;

const LoginForm = styled.form`
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 40px;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  user-select:none;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
  user-select:none;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.1s;
  box-sizing: border-box;

  &:focus {
    border-color: #23a6d5;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px 10px;
  background-color: #23a6d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.1s;
  user-select:none;

  &:hover {
    background-color: #1c8ab9;
  }
`;

const SnsLoginWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const SnsButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: ${props => props.bgColor};
  color: white;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.1s;
  user-select:none;

  &:hover {
    opacity: 0.8;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #23a6d5;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  user-select:none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: #e73c7e;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const ReadOnlyInput = styled(Input)`
  background-color: #f0f0f0;
  color: #666;
  cursor: not-allowed;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Logo = styled.div`
  background:url(${logoImage}) #fff center no-repeat;
  background-size:50%;
  border-radius:50%;
  width: 100px; 
  height:100px;
  margin-bottom:10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);  
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignUpText = styled.p`
text-align: center;
margin: 20px 0;
color: #555;
font-size: 0.9rem;
`;

const SignUpLink = styled.a`
  color: #23a6d5;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [step, setStep] = useState(1);
  const [showSignUp, setShowSignUp] = useState(false);
  const { login, signUp } = useAuth();
  const [error, setError] = useState('');  

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
    } else {
      setEmailError('');
    }
  };


  const handleContinue = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setStep(2);
    } else {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/'); // 로그인 성공 후 메인 페이지로 리다이렉트
    } catch (error) {
      setError(error.message || '로그인에 실패했습니다.');
    }
  };

  const handleSnsLogin = (provider) => {
    // SNS 로그인 로직 구현
    console.log(`${provider} 로그인`);
  };

  const handleShowSignUp = () => {
    setShowSignUp(true);
  };

  const handleBackToLogin = () => {
    setShowSignUp(false);
  };

  if (showSignUp) {
    return <SignUp onBack={handleBackToLogin} />;
  }

return (
  <LoginWrapper>
    <LoginContainer>
      <LogoWrapper>
        <Logo alt="" />
      </LogoWrapper>
      <LoginForm onSubmit={step === 1 ? handleContinue : handleLogin}>
        <Title>로그인</Title>
        {step === 2 && (
          <BackButton type="button" onClick={() => setStep(1)}>
            ← 이메일 변경
          </BackButton>
        )}
        {step === 1 ? (
          <>
            <InputGroup>
              <Label htmlFor="email">이메일주소</Label>
              <Input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="이메일주소를 입력하세요"
                required
              />
              {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            </InputGroup>
            <Button type="submit" disabled={!validateEmail(email)}>계속</Button>
            {step === 1 && (
              <SignUpText>
                계정이 없으신가요? <SignUpLink onClick={handleShowSignUp}>회원가입</SignUpLink>
              </SignUpText>
            )}
            <SnsLoginWrapper>
              <SnsButton bgColor="#DB4437" onClick={() => handleSnsLogin('Google')}>G</SnsButton>
              <SnsButton bgColor="#4267B2" onClick={() => handleSnsLogin('Facebook')}>F</SnsButton>
              <SnsButton bgColor="#1EC800" onClick={() => handleSnsLogin('Naver')}>N</SnsButton>
              <SnsButton bgColor="#FEE500" onClick={() => handleSnsLogin('Kakao')} style={{color: '#000000'}}>K</SnsButton>
            </SnsLoginWrapper>
          </>
          ) : (
            <>
              <InputGroup>
                <Label htmlFor="email-readonly">이메일</Label>
                <ReadOnlyInput
                  type="email"
                  id="email-readonly"
                  value={email}
                  readOnly
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </InputGroup>
              <InputGroup>
                <Label htmlFor="password">비밀번호</Label>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="비밀번호를 입력하세요"
                  required
                />
              </InputGroup>
              <Button type="submit" disabled={!password}>로그인</Button>
            </>
          )}
        </LoginForm>
      </LoginContainer>
    </LoginWrapper>
  );
}

export default Login;