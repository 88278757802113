import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaSignOutAlt, FaTimes } from 'react-icons/fa';

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f8;
  color: #2c3e50;
  padding: 0.5rem 1rem 0rem 1rem;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
  color: #2c3e50;
  user-select:none;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const CenterLayer = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  user-select:none;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  color: #2c3e50;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #34495e;
  }
`;

const ModalContent = styled.p`
  font-size: 16px;
  color: #34495e;
  margin-bottom: 30px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#3498db' : 'transparent'};
  color: ${props => props.primary ? 'white' : '#3498db'};
  border: ${props => props.primary ? 'none' : '2px solid #3498db'};
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.primary ? '#2980b9' : 'rgba(52, 152, 219, 0.1)'};
  }
`;

function TopBar({ onMenuToggle, onLogout }) {
  const [showCenterLayer, setShowCenterLayer] = useState(false);

  const handleCenterLayerClick = () => {
    setShowCenterLayer(!showCenterLayer);
  };

  return (
    <>
      <TopWrapper>
        <LeftSection>
          <MenuButton onClick={onMenuToggle}>
            <FaBars />
          </MenuButton>
          <Logo>ORDARS</Logo>
        </LeftSection>
        <RightSection>
          <IconButton onClick={handleCenterLayerClick} title='로그아웃'>
            <FaSignOutAlt />
          </IconButton>
        </RightSection>
      </TopWrapper>
      {showCenterLayer && (
        <Overlay>
          <CenterLayer>
            <ModalHeader>
              <ModalTitle>로그아웃</ModalTitle>
              <CloseButton onClick={() => setShowCenterLayer(false)}>
                <FaTimes />
              </CloseButton>
            </ModalHeader>
            <ModalContent>정말로 로그아웃 하시겠습니까?</ModalContent>
            <ButtonGroup>
              <Button onClick={() => setShowCenterLayer(false)}>취소</Button>
              <Button primary onClick={onLogout}>확인</Button>
            </ButtonGroup>
          </CenterLayer>
        </Overlay>
      )}
    </>
  );
}

export default TopBar;