import React from 'react';
import styled from 'styled-components';

const BottomWrapper = styled.div`
  background-color: #a5becf;
  flex-basis: ${props => props.isOpen ? '60px' : '0px'};
  flex-shrink: 0;
  transition: 0.1s;
  overflow: hidden;
`;

function Bottom({ isOpen }) {
  return (
    <BottomWrapper isOpen={isOpen}>
      {/* 하단 영역 내용 */}
    </BottomWrapper>
  );
}

export default Bottom;