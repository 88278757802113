import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;

  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginForm = styled.form`
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 40px;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  user-select: none;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
  user-select: none;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.1s;
  box-sizing: border-box;

  &:focus {
    border-color: #23a6d5;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px 10px;
  background-color: #23a6d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.1s;
  user-select: none;

  &:hover {
    background-color: #1c8ab9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: #e73c7e;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const BackLink = styled.a`
  color: #23a6d5;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

function SignUp({ onBack }) {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState(''); 
  
  
  const validateEmail = (email) => {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(String(email).toLowerCase());
    };
  
  const handleEmailChange = (e) => {
      setEmail(e.target.value);
      if (!validateEmail(e.target.value)) {
          setEmailError('유효한 이메일 주소를 입력해주세요.');
      } else {
          setEmailError('');
      }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      try {
        await signUp(email, password);
        navigate('/'); // 회원가입 성공 후 메인 페이지로 리다이렉트
      } catch (error) {
        setError(error.message || '회원가입에 실패했습니다.');
      }
    } else {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
    }
  };
  
  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginForm onSubmit={handleSignUp}>
          <BackLink onClick={onBack}>← 로그인으로 돌아가기</BackLink>
          <Title>회원가입</Title>
          <InputGroup>
            <Label htmlFor="email">이메일주소</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="이메일주소를 입력하세요"
              required
            />
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          </InputGroup>
          <InputGroup>
            <Label htmlFor="password">비밀번호</Label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력하세요"
              required
            />
          </InputGroup>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button type="submit" disabled={!validateEmail(email) || !password}>가입</Button>
        </LoginForm>
      </LoginContainer>
    </LoginWrapper>
  );
}
  
export default SignUp;