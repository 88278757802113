import axios from 'axios';

const API_URL = 'https://api.ordars.com/api/auth';

export const login = async (email, password) => {
  try {
    const pushToken = localStorage.getItem('pushToken');
    const platform = localStorage.getItem('platform');
    
    let payload = { email, password };
    
    if (pushToken && platform) {
      payload = { ...payload, pushToken, platform };
    }
    
    const response = await axios.post(`${API_URL}/login`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const signUp = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};