import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUser, FaBell, FaComments, FaCog, FaTimes } from 'react-icons/fa';

const SidebarWrapper = styled.div`
  display: flex;
  flex-basis: ${props => props.width}px;
  min-width: 60px;
  height: calc(100% - 20px);
  margin: 10px 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  transition: all 0.1s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
`;

const IconBar = styled.div`
  width: 60px;
  min-width: 60px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.active ? '#3498db' : '#ecf0f1'};
  font-size: 24px;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  transition: all 0.2s ease;
  user-select:none;
  position: relative;  // 배지 위치 지정을 위해 추가

  &:hover {
    background-color: #2c3e50;
    color: #3498db;
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: white;
  display: block;
`;

const ContentText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ContentTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  user-select:none;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #34495e;
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewChatBadge = styled(NotificationBadge)`
  background-color: #2ecc71;
`;

function Sidebar() {
  const [activeIcon, setActiveIcon] = useState(null);
  const [sidebarWidth, setSidebarWidth] = useState(60);

  const notificationCount = 12;
  const hasNewChat = true;

  const iconWidths = {
    profile: 500,
    notifications: 500,
    chat: 500,
    settings: 500
  };

  const handleIconClick = (icon) => {
    if (activeIcon === icon) {
      setActiveIcon(null);
      setSidebarWidth(60);
    } else {
      setActiveIcon(icon);
      setSidebarWidth(iconWidths[icon]);
    }
  };

  const handleClose = () => {
    setActiveIcon(null);
    setSidebarWidth(60);
  };

  const renderContent = () => {
    switch(activeIcon) {
      case 'profile':
        return (
          <>
            <ContentHeader>
              <ContentTitle>프로필</ContentTitle>
              <CloseButton onClick={handleClose}><FaTimes /></CloseButton>
            </ContentHeader>
            <ContentText>사용자 프로필 정보를 관리할 수 있습니다.</ContentText>
          </>
        );
      case 'notifications':
        return (
          <>
            <ContentHeader>
              <ContentTitle>알림</ContentTitle>
              <CloseButton onClick={handleClose}><FaTimes /></CloseButton>
            </ContentHeader>
            <ContentText>새로운 알림과 업데이트를 확인할 수 있습니다.</ContentText>
          </>
        );
      case 'chat':
        return (
          <>
            <ContentHeader>
              <ContentTitle>채팅</ContentTitle>
              <CloseButton onClick={handleClose}><FaTimes /></CloseButton>
            </ContentHeader>
            <ContentText>메시지를 주고받을 수 있는 채팅 기능입니다.</ContentText>
          </>
        );
      case 'settings':
        return (
          <>
            <ContentHeader>
              <ContentTitle>설정</ContentTitle>
              <CloseButton onClick={handleClose}><FaTimes /></CloseButton>
            </ContentHeader>
            <ContentText>시스템 설정을 변경할 수 있습니다.</ContentText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <SidebarWrapper width={sidebarWidth}>
      <IconBar>
        <IconButton active={activeIcon === 'profile'} onClick={() => handleIconClick('profile')}>
          <FaUser />
        </IconButton>
        <IconButton active={activeIcon === 'notifications'} onClick={() => handleIconClick('notifications')}>
          <FaBell />
          {notificationCount > 0 && (
            <NotificationBadge>
              {notificationCount > 9 ? '9+' : notificationCount}
            </NotificationBadge>
          )}
        </IconButton>
        <IconButton active={activeIcon === 'chat'} onClick={() => handleIconClick('chat')}>
          <FaComments />
          {hasNewChat && <NewChatBadge>N</NewChatBadge>}
        </IconButton>
        <IconButton active={activeIcon === 'settings'} onClick={() => handleIconClick('settings')}>
          <FaCog />
        </IconButton>
      </IconBar>
      <ContentArea width={sidebarWidth}>
        {renderContent()}
      </ContentArea>
    </SidebarWrapper>
  );
}

export default Sidebar;